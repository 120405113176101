import { AdaptedPageItem, Notification } from './Settings'
import {
  Element,
  Maybe,
  PropertyEnum,
  PropertyValue,
  Scalars,
} from '@/apollo/composition-functions'

export interface ElementWithUrl extends Element {
  url: string
}

export type NormalizedPageData = {
  [key: string]: ElementWithUrl[]
}

export enum PageHideNavigation {
  HEADER = 'header',
  FOOTER = 'footer',
  ALL = 'all',
  ALL_SHOW = 'all-show',
}

export type SeoMetaTag = { name: string; content: string }

export interface AdaptedSeo {
  title?: String
  description?: String
  image?: String
  schema?: String | String[]
  siteName?: String
  metaName?: String
  metaContent?: String
  canonicalHref?: String
  metaTags: SeoMetaTag[]
}

export interface AdaptedComponent {
  code: string | undefined | null
  name: string | undefined | null
  sort: number | undefined | null
  id: string
  type: any
  value: Record<string, string>
}

export interface PropertyEnumWithDescription extends PropertyEnum {
  description?: Maybe<Scalars['String']>
}

export interface AdaptedPage {
  seo: AdaptedSeo
  code: string | undefined | null
  id: string
  name: string | undefined | null
  components: AdaptedComponent[]
  route: AdaptedPageItem[]
  seoFields: Array<PropertyValue | PropertyEnumWithDescription>
  seoFieldsPropertyValuesRelation: Array<any>
  notification?: Notification
  hideBreadcrumbs: boolean
  hideInDropdownMenu: boolean
  hideDelimiters: boolean
  hideNavigation: 'header' | 'footer' | 'all' | 'all-show'
}

// TODO описать тип секции
export interface SectionData {
  [key: string]: any
}
