import https from 'https'
import axios, { AxiosInstance } from 'axios'
import { Context } from '@nuxt/types'
import * as AxiosLogger from 'axios-logger'

const httpsAgent = new https.Agent({
  rejectUnauthorized: false,
})

export const HTTP_REST_FRONTEND_API = (ctx: Context) => {
  const {
    route: { query },
  } = ctx
  const baseUrl = process.server ? ctx.$config.SM_API_URL || '' : ''
  const customAxios: AxiosInstance = axios.create({
    baseURL: baseUrl + (ctx.$config.BASE_PATH || '') + ctx.$config.SM_API_ROOT,
    withCredentials: true,
    httpsAgent,
    headers: {
      'Custom-Ip': `${ctx.customIP}`,
    },
  })

  const returnSelf = <T>(param: T): T => param
  customAxios.interceptors.request.use(returnSelf, (err) => {
    return process.browser
      ? err
      : AxiosLogger.errorLogger(err, {
          dateFormat: 'dd.mm.yy HH:MM:ss',
          headers: false,
          data: true,
          params: true,
        })
  })
  customAxios.interceptors.response.use(returnSelf, (err) => {
    return process.browser
      ? err
      : AxiosLogger.errorLogger(err, {
          dateFormat: 'dd.mm.yy HH:MM:ss',
          headers: false,
          data: true,
          params: true,
        })
  })

  return customAxios
}
