import gql from 'graphql-tag'

export const vacancies = gql`
  query getVacancies($filter: ElementWhereInput, $first: Int) {
    elementsCount(where: $filter)
    elements(first: $first, where: $filter, orderBy: { createdAt: desc }) {
      name
      code
      createdAt
      propertyValues {
        value
        id
        property {
          name
          code
        }
      }
      propertyValuesRelation {
        property {
          code
        }
        targetElement {
          name
        }
      }
    }
  }
`
