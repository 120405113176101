import gql from 'graphql-tag'

export const dataVacancyFilters = gql`
  query listsVacancyFilters {
    specialties: elements(
      where: { iblock: { code: { equals: "vacancySpecializations" } } }
    ) {
      name
      id
    }
    regions: elements(
      where: { iblock: { code: { equals: "vacancyRegions" } } }
      orderBy: { name: asc }
    ) {
      name
      id
    }
  }
`
