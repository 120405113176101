import { GetVacanciesQueryVariables } from '../apollo/composition-functions'
import { vacancies } from '../apollo/queries/vacancies'
import { listVacanciesAdapter } from '../apollo/adapters/modules/listVacanciesAdapter'
import { dataVacancyFilters } from '../apollo/queries/dataVacancyFilters'
import { vacancyFiltersAdapter } from '../apollo/adapters/modules/vacancyFiltersAdapter'

type query = { [key: string]: string }

export const searchVacanciesRequest = async (
  vacanciesQuery: query,
  app: any,
  components: [any]
) => {
  const { query } = app.apolloProvider.defaultClient
  const vacancyFilters: GetVacanciesQueryVariables = {
    filter: {
      AND: [],
      iblock: { code: { equals: 'vacancy' } },
    },
    first: 5,
  }
  if (vacancyFilters.filter?.AND) {
    if (vacanciesQuery.search && typeof vacanciesQuery.search === 'string') {
      vacancyFilters.filter.AND.push({
        OR: [
          { name: { contains: vacanciesQuery.search } },
          {
            propertyValuesRelation: {
              some: {
                targetElement: {
                  name: { contains: vacanciesQuery.search },
                },
              },
            },
          },
        ],
      })
    }
    if (vacanciesQuery.region && typeof vacanciesQuery.region === 'string') {
      vacancyFilters.filter.AND.push({
        propertyValuesRelation: {
          some: {
            property: { code: { equals: 'region' } },
            targetElement: { id: { equals: vacanciesQuery.region } },
          },
        },
      })
    }
    if (
      vacanciesQuery.speciality &&
      typeof vacanciesQuery.speciality === 'string'
    ) {
      vacancyFilters.filter.AND.push({
        propertyValuesRelation: {
          some: {
            property: { code: { equals: 'specialization' } },
            targetElement: { id: { equals: vacanciesQuery.speciality } },
          },
        },
      })
    }
  }
  const [
    {
      data: { elements, elementsCount },
    },
    {
      data: { specialties, regions },
    },
  ] = await Promise.all([
    query({ query: vacancies, variables: vacancyFilters }),
    query({ query: dataVacancyFilters }),
  ])

  return {
    components: components.map((component) => {
      if (
        [
          'sectionSearchVacancies',
          'sectionSearchVacanciesStartCareer',
        ].includes(component.type)
      ) {
        return {
          ...component,
          value: {
            ...component.value,
            initialListVacancies: listVacanciesAdapter(elements),
            listRegions: vacancyFiltersAdapter(regions),
            listSpecialties: vacancyFiltersAdapter(specialties),
            initialTotalCountVacancies: elementsCount,
            searchError: false,
          },
        }
      }
      return component
    }),
  }
}
