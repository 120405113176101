export const breadcrumbsSchemaBuilder = (
  list: any[],
  baseUrl: string
): string => {
  if (!list || !list.length) return ''

  const emoji = ['⭐', '⚡', '☑️', '🔸', '⏩']

  // eslint-disable-next-line array-callback-return
  const items = list
    .filter((el) => el.fullPath !== '/')
    .map((el, i) => {
      return {
        '@type': 'ListItem',
        position: i + 2,
        name: emoji[i] + ' ' + el.name,
        item: baseUrl + el.fullPath,
      }
    })

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'РГС',
        item: baseUrl,
      },
      ...items,
    ],
  }
  return JSON.stringify(schema)
}
