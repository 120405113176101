export const vacancyFiltersAdapter = (data: any) => {
  return data.map((item) => {
    const { name, id } = item
    const value = id
    return {
      name,
      value,
    }
  })
}
