










import {
  defineComponent,
  computed,
  useRoute,
  useStore,
} from '@nuxtjs/composition-api'
import { Context } from '@nuxt/types'
import Mediator from '../components/Mediator.vue'
import { pageRequest } from '@/composition/pageRequest'
import { pageHead } from '@/packages'
import { yaHitPage } from '@/composition/ym'
import { breadcrumbsSchemaBuilder } from '@/composition/breadcrumbsSchemaBuilder'

export default defineComponent({
  name: 'ConstructorPage',
  components: {
    Mediator,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const hideContent = computed(() => {
      return route.value.query?.result === 'success'
    })
    const breadcrumbs = computed(() =>
      store.getters('breadcrumbs/GET_BREADCRUMBS')
    )
    return {
      breadcrumbs,
      hideContent,
    }
  },
  async asyncData(ctx: Context) {
    return await pageRequest(ctx)
  },

  head() {
    const { seo } = this
    const defaultHead = pageHead(seo)
    defaultHead.script = []
    if (defaultHead.schema) {
      defaultHead.script = defaultHead.schema
        .filter((schema) => schema)
        .map((schema) => ({
          type: 'application/ld+json',
          json: JSON.parse(schema),
        }))
    }
    if (this.breadcrumbs) {
      defaultHead.script.push({
        type: 'application/ld+json',
        json: JSON.parse(
          breadcrumbsSchemaBuilder(this.breadcrumbs, 'https://www.rgs.ru')
        ),
      })
    }
    if (this.schemaProduct) {
      defaultHead.script.push({
        type: 'application/ld+json',
        json: this.schemaProduct,
      })
    }
    return defaultHead
  },
  mounted() {
    setTimeout(() => yaHitPage(), 20)
    if (this.setThemeOnClient) {
      this.setThemeOnClient = false
      this.$store.commit('settings/SET_THEME', this.theme)
    }
    if (window.flocktory?.reInit) {
      window.flocktory.reInit()
    }
  },
})
